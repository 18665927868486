.sidebarSecondary {
	height: 100%;
	width: 20vw;
	position: relative;
	background: #1d1d1d;
}

.activeSecondary {
    position: absolute;
	z-index: -1;
	top: 0;
}

@keyframes scale-up-hor-left {
	from {
        position: relative;
        z-index: unset;
	}
	to {
        position: absolute;
        z-index: -1;
	}
}

.contentSidebarSecondary {
	padding-top: 6.5rem;
	margin-left: 4vw;
}

.accHead {
	display: flex;
	position: relative;
}

.titleAccordion {
	font: 14px/16px Helvetica;
	color: white;
	margin: 0;
}

.accHead img {
	margin-left: auto;
}

.activePage {
	background: black;
}

.activePage p {
	color: #f58220;
}

.titleItem {
	padding: 0.8rem 1.5rem 0.8rem 1.5rem;
}