.boxBtn {
    background-color: white;
    border-radius: 2px;
    width: 100% !important;
    text-align: left;
    padding: .375rem 4px .375rem 1rem;
    border: 1px solid #ced4da;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.dropdownMenu {
    min-width: 100%;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.icon {
    width: 10px !important;
}

.item {
    cursor: pointer;
    margin: 1px 0;
    padding: 0 1rem;
    /* font: normal 14px Montserrat; */
}