.c_crop--fixed {
    width: 25vw;
    height: auto;
}
.ReactCrop {
    /* width: 60%; */
}

.ass-d-p-tabpane-1, .ass-d-p-tabpane-2 {
    height: 100%;
    width: 100%;
}

#c-main-ass-d-p > div.tab-content {
    height: 100%;
    width: 100%;
}

#c-main-ass-d-p {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    padding: .25rem;
}

#c-main-thumb-d-p {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    border: 1px solid black;
    padding: .25rem;
}

.file-input-custom-u, .fc-ux {
    width: auto;
    height: 2rem;
    overflow: hidden;
    position: absolute;
    top: 4rem;
    right: 1rem;
    z-index: 2;
    border: 1px solid black;
    border-radius: 20px;
}

.fc-ux {
    top: 1rem;
}

.file-input-custom-u > .custom-file-input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    padding: .25rem;
}

/* .file-input-custom-u:hover label{
    background-color: #ffffff;
    border-color: #000000;
} */

.file-input-custom-u label {
    display: block !important;
    position: relative !important;
    width: 100%;
    height: 39px;
    color: #000000;
    background-color: #ffffff;
    border-color: #000000;
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding: .25rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    font-weight: 400 !important;
    cursor: pointer;
    transition: transform .2s ease-out;
}

.file-name-custom {
    position: absolute;
    bottom: -35px;
    left: 10px;
    font-size: 0.85rem;
    color: #555;
}

.file-input-custom-t {
    position: relative;
    overflow: hidden;
    margin: auto auto auto 1rem;
}

.file-input-custom-t label {
    display: block !important;
    position: relative !important;
    width: auto;
    height: auto;
    margin: auto;
    color: #000000;
    background-color: transparent;
    border-color: #000000;
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding: .25rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400 !important;
    cursor: pointer;
    transition: transform .2s ease-out;
}

.file-input-custom-t > .custom-file-input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    padding: .25rem;
}

#ass_d_page_tab div.data-table-extensions {
    display: none;
}
