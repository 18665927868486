#table_on_logs th {
    text-align: center;
}

#tr_on_logs td {
    text-align: center;
}

.map__container {
    background-color: aqua;
}


#collection-prefix .css-2b097c-container {
    margin-top: 0 !important;
}