.box-asset { 
    width: 100px;
    /* margin-top: 10px; 
    margin-right: 1.1%; */
    margin-bottom: 10px;
    height: 100px;
    border: 1px solid gray; 
    /* float: left; */
    /* background-color: #F1f1f1; */
    cursor:pointer;
    position: relative;
    
}

.center-content {
    /* border:1px solid gray; */
    /* border-style: dashed double; */
    
    /* height:300px; */
    background-color: #F1f1f1;
    position: relative;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered-image {
    position: absolute;
}