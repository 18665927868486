.main-sidebar-page{
    min-height: calc(100vh - 56px);
    height: 100%;
    width:300px;
    z-index: 999;
    position: absolute;
    /* margin-top:2px; */
    margin-left:250px;
    /* overflow: scroll; */
    overflow-y: hidden;
}

.main-sidebar {
	min-height: calc(100vh - 56px) !important;
    height: 100% !important;
}
.main-sidebar, .main-sidebar::before {
    width: 350px;
}

.sidebar-mini.sidebar-collapse #DashboardNew .main-sidebar.sidebar-focused, .sidebar-mini.sidebar-collapse .main-sidebar:hover {
    width: 4.6rem;
    /* width: 350px; */
}

.sidebar-mini.sidebar-collapse #DashboardNew .main-sidebar:hover .sidebar {
	transition: unset !important;
	margin: 0;
}

.sidebar-mini.sidebar-collapse #DashboardNew .main-sidebar .nav-sidebar .nav-link p, .sidebar-mini.sidebar-collapse #DashboardNew .main-sidebar .sidebar .info {
	visibility: hidden !important;
}

.contentPage {
	padding: 2rem 2rem 2rem 2rem;
}

.pageCollapse {
	width: 100%;
	height: 100%;
	background: #f9f9f9 0% 0% no-repeat padding-box;
	border-radius: 0px 0px 3px 3px;
}

.topContent {
	width: 100%;
	height: 56px;
	background: #e2e2e2 0% 0% no-repeat padding-box;
	display: flex;
	position: relative;
}

.topContent span {
	font: 20px/24px Helvetica;
	color: black;
	margin: auto auto auto 1rem;
}

.iconList {
	height: 28px;
	width: 28px !important;
	background: #d4d4d4 0% 0% no-repeat padding-box;
	margin: auto 0 auto 1rem;
	padding: 0.1rem;
}

.iconListW {
    background: transparent;
	margin: auto 0 auto auto;
}

.boxToogle {
	width: 100%;
	height: 56px;
	display: flex;
	padding: 1rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 2px #00000029;
	border-radius: 3px 3px 0px 0px;
	cursor: pointer;
}

.boxToogle span {
    width: 33.3%;
	font: 16px/19px Helvetica;
	text-transform: uppercase;
	margin: auto auto auto 0;
}

.boxToogle > .iconUp {
    width: 33.3%;
	margin: auto;
}

.boxToogle > .iconListLeft {
    width: 33.3%;
	display: flex;
}

@media only screen and (max-width: 1441px) {
	.main-sidebar, .main-sidebar::before {
		width: 280px;
	}
}