#article-form {

}

.article-form-left {

}

.article-form-right {
    margin-left: 30px;
    width: 45%;
}

.ck-editor__editable {
    min-height: 100px !important;
    height: 300px !important;
}

#art-s-form nav {
    padding: 0 .5rem;
}

.select-category-list .react-dropdown-select-dropdown{
    top: 120%;
    width: 100%;
}