.text {
    color: #707070;
}

.text p {
    margin: 0;
}

.name {
    font-weight: 500;
    font-size: 16px;
}

.title {
    font-weight: 500;
    font-size: 14px;
}

.phone {
    font-weight: 500;
    font-size: 16px;
}

.email {
    font-weight: 500;
    font-size: 14px;
}

.select {
    border: 1px solid #BDBDBD;
    border-radius: 3px;
}

.ButtonSecondary {
    background-color: #c8ccdc;
    color: #223771;
    font: normal 16px/19px Helvetica;
    border: none;
    border-radius: 3px;
    height: 3rem;
}

.ButtonSecondary:hover, .ButtonSecondary:focus, .ButtonSecondary:active {
    background-color: #223771 !important;
    color: white !important;
}

@media only screen and (max-width: 1500px) {
    .text {
        margin-bottom: .5rem;
        flex: 0 0 100%;
        max-width: 100%;
    }
}