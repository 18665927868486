.contentHeader {
  padding: 0;
}

@media (min-width: 1500px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .main-footer {
    padding-left: 355px !important;
    margin-left: 0 !important;
  }
  body:not(.sidebar-mini-md) .main-header {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 1023px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .main-footer {
    padding-left: 285px;
    margin-left: 0 !important;
  }
  body:not(.sidebar-mini-md) .main-header {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .content-wrapper {
    padding-left: 80px !important;
    margin-left: 0 !important;
  }
  .sidebar-mini.sidebar-collapse .main-footer {
    padding-left: 22.5rem !important;
    margin-left: 0 !important;
  }
  .sidebar-mini.sidebar-collapse .main-header {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1441px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .main-footer {
    padding-left: 285px;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper {
    /* padding-left: 280px !important; */
  }
}

.nav-pills .nav-link {
  color: #c2c7d0 !important;
}

.sd1-active-items a.active {
  color: #f16b26 !important;
}

.sd1-active-templates {
  color: #f16b26 !important;
}

.linkItem {
  /* display: none !important; */
}

.activeLink {
  color: #f16b26 !important;
}

.new-nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.new-nav-link:hover,
.new-nav-link:focus {
  text-decoration: none;
}

.new-nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f16b26 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

a.lang.active {
  border: solid #007bff 2px !important ;
}
a.nav-link.active {
  /* border: solid #007BFF 2px !important ; */
}

.menu-list-edit-wrapper {
  position: absolute;
  z-index: 1000;
  /* right: 20px !important; */
  top: 12px !important;
  color: white;
}

.menu-list-arrow-wrapper {
  /* position: absolute;
  z-index: 1000;
  right: 20px !important;
  top: 12px !important;
  background-color: #e9ecef;
  color: #2b2b2b;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  cursor: pointer; */
}

div.menu-list-arrow-wrapper:hover {
	
	background-color: rgba(0, 0, 0, 0.5);
	color: #2b2b2b;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }


.menu-list-input-wrapper {
  position: absolute;
  z-index: 1000;
  left: 40px !important;
  top: 15px !important;
  color: white;
  width: 100%;
}

.location-trash {
  position: absolute;
  z-index: 1000;
  right: 20px !important;
  top: 12px !important;
}

.menu-detail-edit-wrapper {
  /* right: 20px !important; */
  color: #0069d9;
  cursor: pointer;
}

.checks {
  /* position: absolute; */
}

.list-card-row {
  position: absolute;
  z-index: 1000;
  background: rgba(0, 0, 0, 0);
  right: 10px;
  border: none;
  color: white;
}

.list-card-row-checkbox {
  position: absolute;
  z-index: 1001;
  top: 60px;
  left: 5px;
  border: none;
  /* color: white; */
}

.checkbox {
  position: absolute;
  z-index: 1000;
  top: 18px;
  margin-left: 5px;
  margin-right: 10px;
}

.back-button {
  cursor: pointer;
  display: inline !important;
}

.previous {
  width: 40px;
  color: black;
}

.cancel-user {
  /* width: 100%; */
  height: 40px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  display: inline-flex;
  justify-content: end;
  padding: 0.5rem 0.75rem;
  color: black;
  white-space: nowrap;
  cursor: pointer;
  float: right;
  margin-right: 15px;
}

.faa-edit {
  color: white !important;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  height: 60px !important;
}

div.back-sect-detail:hover {
	/* position: absolute; */

  border: 1px solid #007bff !important;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.back-sect-detail {
  background-color: white;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  padding: 0.9rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 0 solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.sect-menu {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}