.topNav {
	width: 100vw;
	height: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
}

.navLogo {
	width: 20vw;
	display: flex;
}

.navLogo img {
	width: 92px;
	height: 31px;
	margin: auto;
}

.navSettings {
	height: 32px;
	width: 32px;
	border: 2px solid #ffffff;
	background: #e2e2e2 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 50%;
    margin: auto 5rem auto auto;
    position: relative;
}

.navIcon {
	width: 16px;
	height: 16px;
	display: flex;
    background: #223771;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: -5px;
}

.navIcon img {
	height: 10px;
	width: 10px;
	margin: auto;
}

.customContainer {
	background: #f1f1f1;
}
