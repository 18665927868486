.asset-form-content-right {
    margin-left: 30px;
    font-size: 12px;
    width: 56%;
}

.asset-form-content-left {}

.preview-box {
    border: 1px solid gray;
    margin-right: 10px;
    margin-top: 10px;
}

.preview-box-text {
    margin-right: 10px;
}

.preview-fhd-plus {
    height: 100px;
}

.preview-fhd {
    height: 80px;
}

.preview-hd {
    height: 60px;
}

.preview-mobile {
    height: 40px;
}

.box-asset {
    width: 100px;
    margin-top: 10px;
    margin-right: 1.1%;
    height: 100px;
    border: 1px solid gray;
    float: left;
    background-color: #F1f1f1;
}

.box-upload-image {
    border: 1px solid gray;
    border-style: dashed double;
    width: 100%;
    height: 342px;
    background-color: #F1f1f1;
    position: relative;
    overflow: hidden;
}

#box-upload-assets > div.tab-content{
    height: calc(100% - 42px);
    width: 100%;
}

#box-upload-assets > div.tab-content > div.tab-pane{
    height: 100%;
    width: 100%;
}

.box-upload-image .content {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-file-input {
    opacity: 0;
    width: auto;
    height: auto;
    position: absolute;
}

.file-input-custom:hover label{
    background-color: #0069d9;
    border-color: #0062cc;
}

.file-input-custom label {
    display: block !important;
    position: relative !important;
    width: 100%;
    height: 39px;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding: .375rem .75rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400 !important;
    cursor: pointer;
    transition: transform .2s ease-out;
}

.file-name-custom {
    position: absolute;
    bottom: -35px;
    left: 10px;
    font-size: 0.85rem;
    color: #555;
}

.img-thumbnail {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ass-sel-update {
    display: flex;
    width: 100%;
    height: 100%;
}

.ass-sel-update > p:first-child {
    width: 70%;
}

.ass-sel-update > div {
    width: 30%;
    height: 2rem;
    display: flex;
    position: relative;
    margin-left: 1.5rem;
    color: white;
    /* background: #007bff; */
    cursor: pointer;
}