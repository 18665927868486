.boxProfile {
	display: flex;
	padding: 1.5rem;
}

.boxLogout {
	margin: auto auto 0 0;
}

.profile {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	background-color: transparent;
	display: flex;
	margin-right: 1rem;
	border: 1px solid white;
}

.profile img {
	height: 20px;
	width: 20px;
	object-fit: contain;
	margin: auto;
}

.boxProfile span {
	margin: auto 0;
	text-align: left;
	font: 14px/16px Helvetica;
	color: #ffffff;
}

.borderLine {
	background-color: #2b2b2b;
	width: 100%;
	height: 20px;
	position: relative;
	display: flex;
}

.borderLine hr {
	margin: auto 1.5rem;
	width: 100%;
	background: white;
}

.titleItem {
	padding: 0.8rem 1.5rem 0.8rem 1.5rem;
	cursor: pointer;
}

.titleIcon {
	height: 20px;
	width: 20px;
	object-fit: contain;
	margin-right: 1rem;
}

.accDetail {
	background-color: #2b2b2b;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.mainDetailContent {
	padding-left: calc(2.5rem + 20px);
	margin-top: 1rem;
	margin-bottom: 1rem;
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
}

/* .mainDetailContent:after {
    content: '';
    position: absolute;
    top: -1rem;
    right: 0;
    margin-right: -14px;
    width: 2rem;
    height: 3rem;
    border-top: solid 14px transparent;
    border-left: solid 14px transparent;
    background-color: blue;
    border-radius: 10px;
    z-index: -1;
} */

.toogle {
	position: absolute;
	top: -1rem;
	right: 0;
	margin-right: -14px;
	width: 1rem;
	height: 3rem;
	border-top: solid 14px transparent;
	border-left: solid 14px transparent;
	background-color: #223771;
	border-radius: 10px;
}

.detailContent {
	padding-left: calc(2.5rem + 20px);
	margin-top: 1rem;
	margin-bottom: 1rem;
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
}

.accDetail span {
	color: white;
	font: 12px/14px Helvetica;
	position: relative;
	width: 100%;
}

.accDetail span.active:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 8px;
    margin-right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 12px 8px 0;
    border-color: transparent #ffffff transparent transparent;
}

.sidebarPrimary {
	width: 20vw;
	height: calc(100vh - 40px);
	display: flex;
	flex-direction: column;
	position: relative;
	transition: 0.5s width ease-in-out;
}

.activePrimary {
	width: 4vw;
	height: calc(100vh - 40px);
	background: #333333 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	position: absolute;
	z-index: 2;
	transition: 0.5s width ease-in-out;
}

div.activePrimary > .boxProfile > .profile {
    margin: auto;
}

div.activePrimary > .boxProfile span {
    display: none;
}

div.activePrimary > div.customAccordion > div {
    display: flex;
    flex-direction: column;
}

div.activePrimary > div.customAccordion > div > .titleItem {
    margin: auto;
}

div.activePrimary > div.customAccordion > div > div.accDetail span {
	display: none;
}

div.activePrimary > div.customAccordion > div > div.accDetail > .mainDetailContent > .toogle {
    margin-right: calc(-2vw + .5rem);
    width: .5rem;
    border: none;
}

div.activePrimary > div.customAccordion > div > div.accDetail > .mainDetailContent:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 8px;
    margin-right: calc(-2vw + .5rem);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 8px 4px 0;
    border-color: transparent #ffffff transparent transparent;
}

div.activePrimary > div.customAccordion > div > div.accDetail > .detailContent, 
div.activePrimary > div.customAccordion > div > div.accDetail > .mainDetailContent {
	width: 16px;
    height: 16px;
    position: relative;
    display: block;
    margin: 1rem auto;
    padding-left: 0;
    border: 1px solid #ffffff;
    border-radius: 50%;
}
