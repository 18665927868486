
.ck-editor__editable_inline {
    min-height: 400px;
}

#form-keywords input{
    width: auto;
}

#form-keywords > .fk-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#form-keywords > .fk-content > .fk-icn-add {
    margin: auto 1rem;
    border: 1px solid black;
    padding: .25rem .5rem;
    border-radius: 50%;
    cursor: pointer;
}

#form-keywords > .fk-content > input {
    margin: .25rem .25rem;
}

#form-keywords > .fk-content > .fk-c-in {
    display: flex;
    position: relative;
    margin: .25rem .25rem;
}

.fk-c-in i {
    position: absolute;
    top: 30%;
    right: .5rem;
    z-index: 2;
}

.seo-section {
    border-top: 1px solid rgba(0,0,0,.125);
    padding: 1.25rem 0 0 0;
}


