.table_container {
    overflow-x: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table {
    width: 100%;
}

.table tr {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, .12);
    height: 48px;
    transition: box-shadow 150ms;
}

.table tbody tr:hover {
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
}

.table td, .table th {

    padding-right: 16px;
    padding-left: 16px;
}

.pagination_container {
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pagination {
    font-size: 22px;
    line-height: 22px;
}

.pagination ul {
    margin-bottom: 0;
}

.item_class a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: rgba(0, 0, 0, .54);
    fill: rgba(0, 0, 0, .54);
    border-radius: 50%;
    transition: background-color 150ms;
}

.item_class a:hover {
    background-color: rgba(0, 0, 0, .12);
}

.disabled_class a {
    cursor: unset;
    color: rgba(0, 0, 0, .18);
    fill: rgba(0, 0, 0, .18);
}

.disabled_class a:hover {
    background-color: unset;
}