#s-adm-page,
#s-adm-user-page,
#s-adm-us-add {
  padding: 1rem 1.5rem;
}

#s-adm-page .switchCk {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 30px;
  margin: auto 1rem;
}

#s-adm-page .switchCk input {
  display: none;
}

#s-adm-page .sliderCk {
  background-color: #00599d;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

#s-adm-page .sliderCk:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 12px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 12px;
}

#s-adm-page input:checked + .sliderCk {
  background-color: #ccc;
}

#s-adm-page input:checked + .sliderCk:before {
  transform: translateX(12px);
}

#s-adm-page .sliderCk.round {
  border-radius: 34px;
}

#s-adm-page .sliderCk.round:before {
  border-radius: 50%;
}

#s-adm-page > div.w-50 > div > div.w-40.d-flex.content-lg > div > span.active {
  color: #00599d;
}

#s-adm-page
  > div.w-50
  > div
  > div.w-40.d-flex.content-lg
  > div
  > span.inactive {
  color: #e2e2e2;
}
#s-adm-page .action {
  float: right;
}
#s-adm-page .action svg:first-child {
  margin-right: 0.5rem;
}
#s-adm-page .action .action-content svg {
  border-radius: 6px;
  background: #fafafa;
  box-shadow: 8px 8px 16px #e1e1e1, 0px 0px 16px #f1f1f1;
  padding: 0.5rem;
  width: auto;
  height: auto;
  cursor: pointer;
}

/* SuperAdimUserListPage */

.sa-field {
  border-radius: 16px;
  height: 2rem;
  font: 12px/14px Helvetica;
  letter-spacing: 0px;
  color: #707070;
}

.sa-field-nonactive {
  opacity: 0.5;
  cursor: not-allowed;
}

.sa-btn {
  width: 100%;
  height: 32px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid #d4d4d4;
  border-radius: 16px;
  margin-left: 0.5rem;
  cursor: pointer;
  justify-content: center;
}

.sa-btn-bl {
  background: #223771 0% 0% no-repeat padding-box;
  color: #ffffff;
}

.sa-btn-bl:hover {
  color: #ffffff;
}

.sa-btn-wh {
  background: #e2e2e2 0% 0% no-repeat padding-box;
  color: #707070;
}

.s-adm-users-tbl-con {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.s-adm-users-tbl-con:hover,
.s-adm-users-tbl-con.active {
  box-shadow: 0px 3px 6px #00000029;
}

.c-ck-custom {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  text-align: left;
  font: 16px Helvetica;
  height: 16px;
  width: 100%;
  letter-spacing: 0px;
  color: #707070;
  margin: auto 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.c-ck-custom input {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.c-ck-custom:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.c-ck-custom input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.c-ck-custom input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.c-ck-custom .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-ck-custom .checkmark.ck-blue:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #223771;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.w-5 {
  width: 5%;
  display: flex;
}

.w-10 {
  width: 10%;
  display: flex;
}

.w-20 {
  width: 20%;
}

.w-10 p,
.w-5 p {
  margin: auto;
}

.s-adm-user-tbl-head {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 0 1rem;
}

.s-adm-users-tbl-con > div:first-child,
.s-adm-user-tbl-head > div:first-child,
.s-adm-users-tbl-con > div:nth-child(2),
.s-adm-user-tbl-head > div:nth-child(2) {
  margin: auto 0;
}

/* .s-adm-users-tbl-con > div:not(:first-child),
.s-adm-user-tbl-head > div:not(:first-child) {
  margin: auto 1rem;
} */

.s-adm-users-tbl-con > div,
.s-adm-users-tbl-con > div > p {
  margin: auto;
}

.s-adm-users-tbl-con > div:nth-child(3),
.s-adm-users-tbl-con > div:nth-child(3) > p,
.s-adm-users-tbl-con > div:nth-child(4),
.s-adm-users-tbl-con > div:nth-child(4) > p {
  margin: auto 0.5rem;
}

.s-adm-user-tbl-head > div,
.s-adm-user-tbl-head > div > p {
  margin: auto;
  color: #a4a4a4;
}

.s-adm-user-tbl-head > div:nth-child(3),
.s-adm-user-tbl-head > div:nth-child(3) > p,
.s-adm-user-tbl-head > div:nth-child(4),
.s-adm-user-tbl-head > div:nth-child(4) > p {
  margin: auto 0.5rem;
}

#s-adm-user-page > #c_dt_tbl > #pagination_custom > ul > li.page-item > a, #pagination_custom > ul > li.page-item > a {
  padding: 0.3rem 0.7rem;
}

#pagination_custom > ul > li.page-item.active > a.page-link {
  background-color: #17438B;
  border-color: #17438B;
}

.s-adm-users-tbl-con .gr-priv {
  background: #223771 0% 0% no-repeat padding-box;
  color: #ffffff;
  font: 12px Helvetica;
  padding: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.sp-attention {
  color: red;
}

/* checkbox style */
.custom-ck {
  position: relative;
  /* top: -1.5rem; */
}
.custom-ck input[type="checkbox"] {
  height: 0;
  width: 0;
  position: absolute;
}

.custom-ck input[type="checkbox"] + label {
  position: relative;
  display: flex;
  /* margin: 0.6em 0; */
  align-items: center;
  color: #9e9e9e;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.custom-ck input[type="checkbox"] + label > ins {
  position: absolute;
  display: block;
  bottom: 0;
  left: 1.5em;
  height: 0;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: height 300ms cubic-bezier(0.4, 0, 0.23, 1);
}
.custom-ck input[type="checkbox"] + label > ins > i {
  position: absolute;
  bottom: 0;
  font-style: normal;
  color: #223771;
}
.custom-ck input[type="checkbox"] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.custom-ck input[type="checkbox"] + label:hover,
.custom-ck input[type="checkbox"]:focus + label {
  color: #223771;
}
.custom-ck input[type="checkbox"] + label:hover > span,
.custom-ck input[type="checkbox"]:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}
.custom-ck input[type="checkbox"]:checked + label > ins {
  height: 100%;
}

.custom-ck input[type="checkbox"]:checked + label > span {
  border: 0.5em solid #9e9e9e;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.custom-ck input[type="checkbox"]:checked + label > span:before {
  content: "";
  position: absolute;
  top: 0.6em;
  left: 0.12em;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
  font-size: 23px;
}

.btn-bz {
  background-color: #17438B;
  border-color: #17438B;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
