
.content-header {

}

.content-header .button { 
  float: right;
  
}

.content-header .left-content {
    float: left;
}

.content-header .right-content {
    float: right;
    width: 20%;
}

.arrow-toggle {
    display: inline-block;
    margin-left: 21%;
}

.badge { 
    padding:5px 10px;
}