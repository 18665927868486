#body_article .dataTable {
    margin-top: 1rem;
}

/* #filter-articles {
    margin-right: 65px;
    margin-left: 118px;
}

#filter-articles span {
    font-size: 13px;
} */