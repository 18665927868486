.contentPages {
	height: 100%;
	width: 100%;
}

.topContent {
	width: 100%;
	height: 56px;
	background: #e2e2e2 0% 0% no-repeat padding-box;
	display: flex;
	position: relative;
}

.topContent span {
	font: 20px/24px Helvetica;
	color: black;
	margin: auto auto auto 1rem;
}

.iconList {
	height: 28px;
	width: 28px !important;
	background: #d4d4d4 0% 0% no-repeat padding-box;
	margin: auto 0 auto 1rem;
	padding: 0.1rem;
}

.iconListW {
	background: transparent;
}

.mainContent {
	width: 100%;
	height: 100%;
	padding: 2rem;
	background: #f1f1f1 0% 0% no-repeat padding-box;
	border-radius: 0px 0px 3px 3px;
}

.boxToogle {
	width: 100%;
	height: 56px;
	display: flex;
	padding: 1rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 2px #00000029;
	border-radius: 3px 3px 0px 0px;
	cursor: pointer;
}

.boxToogle span {
	font: 16px/19px Helvetica;
	text-transform: uppercase;
	margin: auto auto auto 0;
}

.boxToogle > .iconUp {
	margin: auto;
}

.boxToogle > .iconList {
	margin: auto 0 auto auto;
}

.customCollapse {
	width: 100%;
	height: 100%;
	background: #f9f9f9 0% 0% no-repeat padding-box;
	border-radius: 0px 0px 3px 3px;
	overflow: hidden;
	transition: height 5s;

}

/* .customCollapse {
	height: 0px;
	width: 100%;
	overflow: hidden;
	transition: height 5s;
}

.isCollapsed {
	max-height: 10000px;
	background-color: black;
	width: 100%;
	transition: height 5s;
} */
