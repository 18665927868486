.topContent {
	width: 100%;
	height: 56px;
	background: #e2e2e2 0% 0% no-repeat padding-box;
	display: flex;
	position: relative;
}

.topContent span {
	font: 20px/24px Helvetica;
	color: black;
	margin: auto auto auto 1rem;
}

.iconList {
	height: 28px;
	width: 28px !important;
	background: #d4d4d4 0% 0% no-repeat padding-box;
	margin: auto 0 auto 1rem;
	padding: 0.1rem;
	cursor: pointer;
}

.iconListW {
    background: transparent;
	margin: auto 0 auto auto;
}

.boxToogle {
	width: 100%;
	height: 56px;
	display: flex;
	padding: 1rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 2px #00000029;
	border-radius: 3px 3px 0px 0px;
	cursor: pointer;
}

.boxToogle span {
    width: 33.3%;
	font: 16px/19px Helvetica;
	text-transform: uppercase;
	margin: auto auto auto 0;
}

.boxToogle > .iconUp {
    width: 33.3%;
	margin: auto;
}

.boxToogle > .iconListLeft {
    width: 33.3%;
	display: flex;
}