.mglbl-modal .modal-content {
  padding: 2rem;
}

.mglbl-modal .modal-content .modal-header {
  background-color: #fff !important;
}

.mglbl-btn-add {
  width: 30%;
  height: 48px;
  background: #00599d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #00599d;
  border-radius: 28px;
  color: white;
  flex-wrap: nowrap;
}

.mglbl-btn-add:focus,
.mglbl-btn-cancel:focus {
  border: unset;
  outline: none;
}

.mglbl-btn-cancel {
  width: 30%;
  height: 48px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 28px;
  color: #00599d;
  flex-wrap: nowrap;
}

/* SAP Create Client  */
.mglbl-modal .modal-content .modal-body .lg-list {
  padding: 0 0.5rem;
}

.mglbl-modal .modal-content .modal-body .lg-list div {
  padding: 0;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
}

.dropzone {
  width: 120px;
  height: 120px;
  color: #00599d;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #00599d;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.dropzone p {
  text-align: center;
}
.dropzone-action {
  width: 120px;
  display: flex;
  margin: 0.5rem 0 2rem 48px;
}
.dropzone-action svg {
  border-radius: 6px;
  background: #fafafa;
  box-shadow: 8px 8px 16px #e1e1e1, 0px 0px 16px #f1f1f1;
  padding: 0.5rem;
  width: auto;
  height: auto;
  cursor: pointer;
}

.dropzone .thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  /* margin-bottom: 8px;
  margin-right: 8px; */
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.dropzone .thumbInner {
  display: flex;
  min-width: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.dropzone .thumbInner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
