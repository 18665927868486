.removeAfter::after {
    content: none;
}

.label {
    font-size: 12px;
    font-weight: normal;
    color: #707070;
}

.bgPrimary {
    background-color: #00599D;
}

.CardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 19px;
}

.ButtonPrimary {
    background-color: #00599D;
    color: white;
    border-radius: 3px;
}

.ButtonSecondary {
    background-color: #c8ccdc;
    color: #223771;
    font: normal 16px/19px Helvetica;
    border: none;
    border-radius: 3px;
    height: 3rem;
}

.ButtonSecondary:hover, .ButtonSecondary:focus, .ButtonSecondary:active {
    background-color: #223771 !important;
    color: white !important;
}

.History {
    overflow-y: auto;
    max-height: 320px;
}

.Note {
    padding: 0.5rem 1rem;
}

.Note.bgGreen {
    background-color: #D4FFD4;
}

.Note.bgPink {
    background-color: #FFE1E1;
}

.DocumentCheckbox:hover {
    cursor: pointer;
}