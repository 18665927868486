.accHead {
    display: flex;
    position: relative;
}

.titleAccordion {
    font: 14px/16px Helvetica;
    color: white;
    margin: 0;
}

.accHead img {
    margin-left: auto;
}

.activeSecondary > .titleAccordion > img{
    margin: auto;
}

.activeSecondary > img, .activeSecondary span{
    display: none;
}

