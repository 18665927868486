.isNone {
    display: none;
}


.isExpand {
    display: none !important;
    height: 0;
}


.isExpand2 {
    display: none !important;
    height: 0;
}

.styleNew {
    cursor: pointer;
}

.card_setting {
    height: 100%;
    padding: 1.25rem;
    margin-bottom: 1rem;
}


.card_setting2 {
    height: 100%;
    margin-bottom: 2rem;
    padding: 1.25rem;
}


.isExpandFooter {
    display: none !important;
    height: 0;
    padding: 0 !important;
}

.stylesFooter {
    margin-bottom: 1rem;
    margin-right: 1rem;
}


.offMb {
    margin-bottom: 0 !important;
}


/* .isNotExpand {
    padding: unset;
} */