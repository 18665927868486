.navItemCustom {
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
	transition: height 2s ease-in-out;

}

.padActive {
	padding-left: 2.5rem;

}

.padInActive {
	/* padding-left: 1rem !important; */
}

div.padActive {
	cursor: pointer;
	
}

div.padActive i,
div.padActive p {
	color: #c2c7d0;
}

.menuOpen > .nvTrOpen {
	-webkit-animation: scaleUpVerDown 0.4s linear both;
	animation: scaleUpVerDown 0.4s linear both;
}

.menuClose > .nvTrClose {
	-webkit-animation: scaleUpVerTop 0.4s linear both;
    animation: scaleUpVerTop 0.4s linear both;
}

@-webkit-keyframes scaleUpVerDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-5%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}
@keyframes scaleUpVerDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-5%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-webkit-keyframes scaleUpVerTop {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-5%);
	}
}
@keyframes scaleUpVerTop {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	100% {
		opacity: 0;
        -webkit-transform: translateY(-5%);
        height: 0;
	}
}

.menuOpen > div > p > i {
	transform: rotate(-90deg);
}

.sidebarOpen > .sidebarItem > p {
    display: none;
}

.sidebarOpen > .sidebarItem > p > i {
    display: block;
}

.menuClose {
	margin-bottom: 0;
}
.sidebarItem {
	/* color: #6c757d; */
	color: #c2c7d0;
	border-radius: 0.25rem;
	display: block;
	padding: 0.5rem 1rem;
	white-space: nowrap;
	overflow: hidden;
}

div.sidebarReportLink li > div > p > a  {
 display: none !important;
 color :#f16b26 !important;
}

p.linkItem {
	display: none !important;
}

.sidebarReportItem {
	/* color: #6c757d; */
	color: #c2c7d0;
	border-radius: 0.25rem;
	display: inline !important;
	/* padding: 0.5rem 1rem; */
	width: 100% !important;
	white-space: nowrap;
	overflow: hidden;
}

.sidebarReportItem  p {
	
	transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease !important;
	/* display: inline !important; */
	-webkit-animation-name: fadeIn !important;
	animation-name: fadeIn !important;
	-webkit-animation-duration: 0.3s !important;
	animation-duration: 0.3s !important;
	-webkit-animation-fill-mode: both !important;
	animation-fill-mode: both !important;
	margin: 0 !important;
	width: 350px !important;
	
}

.sidebarReportItem > i {
	margin-left: 0.05rem;
	font-size: 1.2rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 1.6rem;
}

.sidebarReportItem p > i {
	position: absolute;
	right: 0.3rem;
	top: 0.7rem;
}



.sidebarItem p {
	transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
	display: inline-block;
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	margin: 0;
}

.sidebarItem > i {
	margin-left: 0.05rem;
	font-size: 1.2rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 1.6rem;
}

.sidebarItem p > i {
	position: absolute;
	right: 1rem;
	top: 0.7rem;
}

.nvTr i {
	margin-left: 0.05rem;
	font-size: 1.1rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 1.6rem;
}

.nvTrOpen {
    height: 100%;
	display: block;
	flex-wrap: wrap;
	flex-direction: column;
	list-style: none;
	padding: 0;

}

.nvTrClose {
    /* height: 100%; */
    height: auto;

	/* display: none; */
	flex-wrap: wrap;
	flex-direction: column;
	list-style: none;
    padding: 0;
    /* height: 0; */
    /* transition: height .4s ease-in-out; */
}
