.modal {
    max-width: 100vw;
    margin: 0.5rem;
}

.modal iframe {
    height: calc(100vh - 1rem - 73px);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

@media only screen and (max-width: 420px) {
    .modal iframe {
        height: calc(100vh - 1rem - 54px);
    }

    .title {
        font-size: 14px;
    }
}

@media only screen and (max-width: 325px) {
    .modal iframe {
        height: calc(100vh - 1rem - 75px);
    }
}
