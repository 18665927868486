#text-form {
    /* font: normal normal normal 10px/12px Helvetica Neue LT Std;
    letter-spacing: 0px; */
    color: #000000;
    /* opacity: 1;  */
}

.text-form-left {
    
}

.text-form-right {

    margin-left: 30px;
    width: 47%;
}