.ButtonPrimary {
    background-color: #00599D;
    color: white;
    border-radius: 3px;
}

.ButtonSecondary {
    background-color: #c8ccdc;
    color: #223771;
    font: normal 16px/19px Helvetica;
    border: none;
    border-radius: 3px;
    height: 3rem;
}

.ButtonSecondary:hover, .ButtonSecondary:focus, .ButtonSecondary:active {
    background-color: #223771 !important;
    color: white !important;
}

.bgPrimary {
    background-color: #00599D !important;
}