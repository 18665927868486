.mainSidebar {
	width: 20vw;
	height: calc(100vh - 40px);
    background-color: #1d1d1d;
    position: relative;
    display: block;
}

.wrappedSidebar {
    width: 20vw;
    height: calc(100vh - 40px);
}

