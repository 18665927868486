.sidebar {
    /* background-color:rgba(0,0,0,0.15); */
    background-color: #1d1d1d;
    /* padding-left: 0;
    padding-right:0; */
}

.sidebar-max {
    /* padding-left: 15px; */
}

[class*="sidebar-dark"] .user-panel {}

.img-avatar {
    height: 36px;
    width: 36px;
    position: relative;
    display: flex !important;
    border: 1px solid white;
    padding: 0 !important;
    margin: auto 0 auto 0.8rem;
    border-radius: 50%;
}

.img-avatar i {
    color: white;
    font-size: 1.2em;
    margin: auto;
}

.menu-dropdown {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0;
}

.logo-bz-publish {
    position: absolute;
    bottom: 5vh;
    width: 100%;
}

.footer-bz-publish {
    display: flex;
    justify-content: center;
}

nav {
    padding: -10px;
}

nav ul li .nav-link i.fa {
    margin-right: "200px";
}

/* ================================== */

#side-client-list {
    background-color: #1d1d1d;
    color: white;
    border: unset;
}

.main-sidebar-page {
    margin-left: 0px !important;
    width: 350px !important;
    transition: width 2s;
}

.main-sidebar-page-max {
    width: 350px !important;
}

.main-sidebar-top {
    background: #1d1d1d;
    margin-top: 50px;
}

.main-sidebar:hover .main-sidebar::before {
    width: 4.6rem !important;
}

.borderLine {
    background-color: #2b2b2b;
    width: 100%;
    height: 20px;
    position: relative;
    display: flex;
}

.borderLine hr {
    margin: auto 1.5rem;
    width: 100%;
    background: #707070;
}

.toogleSidebar {
    position: absolute;
    top: 9rem;
    right: 0;
    margin-right: -6px;
    width: 1rem;
    height: 2rem;
    border-top: solid 14px transparent;
    border-left: solid 14px transparent;
    background-color: #223771;
    border-radius: 10px;
}

.toogleSidebarExt {
    position: absolute;
    top: 9rem;
    left: 343px;
    margin-right: 0;
    width: 1rem;
    height: 2rem;
    border-top: solid 14px transparent;
    border-left: solid 14px transparent;
    background-color: #304fa5;
    border-radius: 10px;
}

.sd1-active-items,
.sd1-active-items>p {
    /* color: #f16b26; */
}

.dashboardCustom {
    padding: 0 0 0.5rem 0;
}

.dashboardCustom .info {
    width: 100%;
    padding: 5px 0.75rem 5px 5px;
}

.bounce-right-animation {
    position: absolute;
    right: 20%;
    top: 18px;
    color: white;
    -webkit-animation: bounce-right 2s steps(2, start) 2s infinite both;
    animation: bounce-right 2s steps(2, start) 2s infinite both;
}

@-webkit-keyframes bounce-right {
    0% {
        -webkit-transform: translateX(48px);
        transform: translateX(48px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        -webkit-transform: translateX(26px);
        transform: translateX(26px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    65% {
        -webkit-transform: translateX(13px);
        transform: translateX(13px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    82% {
        -webkit-transform: translateX(6.5px);
        transform: translateX(6.5px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    93% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87%,
    98% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

@keyframes bounce-right {
    0% {
        -webkit-transform: translateX(48px);
        transform: translateX(48px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        -webkit-transform: translateX(26px);
        transform: translateX(26px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    65% {
        -webkit-transform: translateX(13px);
        transform: translateX(13px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    82% {
        -webkit-transform: translateX(6.5px);
        transform: translateX(6.5px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    93% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87%,
    98% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

@media only screen and (max-width: 1441px) {
    .main-sidebar-page {
        margin-left: 0px !important;
        width: 280px !important;
        transition: width 2s;
    }
    
    .main-sidebar-page-max {
        width: 280px !important;
    }
}