/* ================== scroll menu ==================*/
div.scrollmenu {
  display: flex;
  flex-wrap: nowrap !important;
  overflow: auto;
  padding: 0.5rem 0;
  margin-right: 1rem;
}

div.scrollmenu nav .nav-item {
  text-align: center;
  text-decoration: none;
}

div.scrollmenu a.nav-link {
  width: max-content !important;
}

div.scrollmenu a:hover {
  background-color: #007bff;
  color: white !important;
}

div.scrollmenu div {
  flex: 0 0 auto;
  /* overflow-y:auto; */
}

/* ================== end scroll menu ==================*/

.btn-w-ass-or {
  width: 20%;
  height: fit-content;
  padding: 0.5rem 0.5rem;
  border: 1px solid #007bff;
  border-radius: 2rem;
  margin-top: 0.5rem;
  margin-left: auto;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  justify-content: center;
  display: flex;
}

.btn-w-ass-or:hover {
  background-color: rgb(0, 123, 255, 0.8);
}
