.contentBoxActive {
	max-height: 100%;
	width: 100%;
	transition: height 1s ease;
}

.contentBox {
	height: 0;
	width: 100%;
	/* overflow: hidden; */
	transition: height 1s ease;
}

.contentBoxActive:not(:first-child) .wrappContent{
	padding: 0 2rem 2rem 2rem;
}

.wrappContent {
	padding: 2rem;
}

.iconUp,
.iconList {
	height: 24px;
	width: 24px !important;
}

.customCollapse {
	width: 100%;
	height: 100%;
	background: #f9f9f9 0% 0% no-repeat padding-box;
	border-radius: 0px 0px 3px 3px;
}

.box {
	width: 100%;
	height: 248px;
	padding: 1rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 2px #00000029;
	border-radius: 3px;
}

.boxTop {
	display: flex;
}

.boxLeft {
	margin: auto auto auto 0;
	display: flex;
}

.boxLeft span {
	font: 14px/16px Helvetica;
}

.topIcon {
	width: 16px;
	height: 16px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #eeeeee;
	margin: auto 1rem auto auto;
	border-radius: 50%;
}

.boxRight {
	margin: auto 0 auto auto;
}

.boxRight svg:nth-child(2) {
	margin: auto 1rem;
}

.boxBottom {
	width: 100%;
	height: 160px;
	display: flex;
	background: #eeeeee 0% 0% no-repeat padding-box;
	border-radius: 3px;
}

.boxDefault {
	display: flex;
	flex-direction: column;
	margin: auto;
}

.boxDefault span {
	text-align: center;
	font: 12px/16px Helvetica;
	color: #d4d4d4;
	margin-top: 0.5rem;
}
