.BoxSimple {
    border-radius: 10px;
    padding: .5rem 1rem;
    min-width: 168px;
    background-color: #223771;
    color: white;
    font-family: Helvetica;
}

.status {
    font-size: 12px;
    line-height: 14px;
}

.content {
    font-size: 26px;
    line-height: 31px;
    text-align: center;
}

@media (max-width: 1441px) {
    .BoxSimple {
        min-width: 120px;
    }
}