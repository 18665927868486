.base {
    background-color: white;
}

.textBody {
    padding: 1rem 1.5rem;
}

.title {
    display: inline-block;
    font: bold 18px/18px Helvetica;
    margin: 0;
}

.mainLocation {
    display: inline-block;
    font-size: 16px;
    margin: 0 0 0 1rem;
    color: #F16B26;
    font-style: italic;
    font-weight: 500;
}

.subtitle1 {
    font: normal 12px/18px Helvetica;
    color: #223771;
    margin: 0;
}

.subtitle2 {
    font: normal 12px/18px Helvetica;
    color: #707070;
    margin: 0;
}

.detailBox {
    font: normal 12px/18px Helvetica;
    margin-top: 10px;
    column-count: 2;
}

.detailBox p {
    margin: 0;
}

@media only screen and (max-width: 1600px) {
    .mainLocation {
        margin: 0;
    }
}