.w-50 {
    width: 50%;
    padding: 0 1rem;
}
.w-60 {
    width: 60%;
}
.w-40 {
    width: 40%;
}
.title-lg input{
    border-radius: unset;
}
.input-title-lg {
    background: #F1F1F1 0% 0% no-repeat padding-box;
    padding-left: 1rem;
}
.content-lg {
    display: flex;
    border: 1px solid #D4D4D4;
}
.switchCk {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 30px;
  margin: auto 1rem;
}

.switchCk input {
  display: none;
}

.sliderCk {
  background-color: #00599d;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.sliderCk:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 12px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 12px;
}

input:checked + .sliderCk {
  background-color: #ccc;
}

input:checked + .sliderCk:before {
  transform: translateX(12px);
}

.sliderCk.round {
  border-radius: 34px;
}

.sliderCk.round:before {
  border-radius: 50%;
}
