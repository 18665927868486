/* LOCATION PAGE */

/* END LOCATION PAGE */

/* LOCATION DETAIL PAGE */

.leaflet-top, .leaflet-bottom {
    display: flex;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    height: 100%;
}

.loc-header {
    height: 56px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 3px 3px 0px 0px;
}

.loc-header>div {
    padding: 0.5rem 1.5rem;
}

.loc-header label {
    display: flex;
    margin: auto 0;
}

.btn-loc-header div:first-child {
    width: 100%;
    height: 40px;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    color: black;
    white-space: nowrap;
    cursor: pointer;
}

.btn-loc-header div:not(:first-child) {
    width: 100%;
    height: 40px;
    background: #00599d 0% 0% no-repeat padding-box;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    margin-left: 1rem;
    color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
}

.btn-loc-add div:first-child {
    width: 100%;
    height: 40px;
    background: #00599d 0% 0% no-repeat padding-box;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
}

.loc-body {
    display: flex;
    width: 100%;
    position: relative;
    background-color: #F9F9F9;
    padding: 1rem 2rem;
    margin-top: .1rem;
}

.loc-body>div:first-child {
    width: 49%;
}

.loc-body>div:not(:first-child) {
    width: 49%;
}

.loc-body div.form-group {
    display: flex;
}

.loc-body div.form-group label {
    width: 40%;
}

#loc-search-place {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 50%;
    height: 2.5rem;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipsis;
    position: absolute;
    left: 40%;
    top: .6rem;
}

.pac-container {
    z-index: 1100 !important;
}

.row-web {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.p {
    color: rgb(106 106 106) !important;
    margin-bottom: 0;
    width: 40%;
}

.b {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.c {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    width: 90%;
}

.e {
    width: 10%;
    display: flex;
    justify-content: flex-end;
}

.link {
    color: #00599d;
    width: 89%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.p2 {
    color: rgb(106 106 106) !important;
    margin-bottom: 0;
}

.add {
    color: rgb(106 106 106);
    margin-bottom: 0;
    /* text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
}

.loc-body>div:first-child>div:first-child {
    margin-top: 2rem;
}

.btn-loc-add-company {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: -2rem;
}

/* END LOCATION DETAIL PAGE */