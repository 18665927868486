nav.navbar-nav .nav-item .nav-link{ 
    padding: -20px;
}
.topNav {
	width: 100vw;
	height: 50px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
}

div.uname {
    margin-top: 10px;
}

.navLogo {
	width: 350px;
	display: flex;
}

.navLogo a {
	width: 100%;
	height: 100%;
	display: flex;
}

.navLogo img {
	width: 92px;
	height: 31px;
	margin: auto;
}

.navSettings {
	height: 32px;
	width: 32px;
	border: 2px solid #ffffff;
	background: #e2e2e2 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 50%;
    margin: auto 5rem auto 1rem;
    position: relative;
}

.navIcon {
	width: 16px;
	height: 16px;
	display: flex;
    background: #223771;
    border-radius: 50%;
    position: absolute;
	bottom: -.5rem;
    right: -.5rem;
}

.navIcon img {
	height: 10px;
	width: 10px;
	margin: auto;
}

.navLanguage {
	display: flex;
	flex-direction: row;
    position: relative;
	margin: auto 2rem auto auto;
    
}

.navLanguage .nav-item {
	height: 100%;
	/* width: 100%; */
	/* padding: 0 1rem; */
	margin-left: 1rem;
}

.navLanguage > .nav-item > a {
    /* border-style: solid #007BFF 4px !important ; */
	padding: 0px 5px 3px 5px !important;
    background-color: 'red' !important;
}

.slNav {
    display: inline;
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 16px;
    height: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50%;
}

.slNav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
}

.slNav li {
    cursor: pointer;
    /* padding-bottom: 10px; */
}

.slNav li ul {
    display: none;
}

.slNav li:hover ul {
    position: absolute;
    top: 21px;
    right: -15px;
    display: block;
    background: #fff;
    width: 180px;
    padding-top: 0px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li:hover .block {
    top: 0px;
    right: -30px;
    position: absolute;
    height: 120px;
    width: 230px;
}

.slNav li:hover .triangle {
    position: absolute;
    top: .5rem;
    right: -10px;
    z-index: 10;
    height: 14px;
    overflow: hidden;
    width: 30px;
    background: transparent;
}

.slNav li:hover .triangle:after {
    content: "";
    display: block;
    z-index: 20;
    width: 15px;
    transform: rotate(45deg) translateY(0px) translatex(10px);
    height: 15px;
    background: #fff;
    border-radius: 2px 0px 0px 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li ul li {
    position: relative;
    text-align: left;
    background: transparent;
    padding: 15px 15px;
    padding-bottom: 0;
    z-index: 2;
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: column;
}

.slNav li ul li:last-of-type {
    padding-bottom: 15px;
}

.slNav li ul li span,
.slNav li ul li a {
    padding-left: 5px;
}

.slNav li ul li span:hover,
.slNav li ul li span.active,
.slNav li ul li a:hover,
.slNav li ul li a.active  {
    color: #f16b26;
}

.slNav li ul li a {
    color: #000;
}

.contentDropdown {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
}

.contentDropdown ul {
    margin: auto !important;
}